<template>
  <div id="page">
    <main-header />
    <main>
      <cover
        title="Video"
        :image="image" />

      <div class="wrapper width">
        <div
          v-for="video in videos"
          :key="video.id"
          class="video-item">
          <video-embed :url="video.url" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import store from '@/store'

import Cover from '../common/Cover'
import VideoEmbed from './VideoEmbed'
import MainHeader from '../common/Header'
import defaultImage from '@/assets/images/blog.jpg'

export default {
  async beforeRouteEnter (to, from, next) {
    try {
      await store.dispatch('videos/load')
      next()
    } catch (error) {
      console.error(error)
    }
  },

  components: {
    Cover,
    MainHeader,
    VideoEmbed
  },

  data () {
    return {
      image: defaultImage
    }
  },

  computed: {
    videos () {
      return this.$store.getters['videos/list']
    }
  }
}
</script>
