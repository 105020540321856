<template>
  <main>
    <sidebar v-model="menuOpened" />
    <section :class="{ tiny: menuOpened }">
      <navigation />
      <router-view />
    </section>
  </main>
</template>

<script>
import Sidebar from '@/components/ui/layout/Sidebar'
import Navigation from '@/components/ui/layout/Navigation'

export default {
  components: {
    Sidebar,
    Navigation
  },

  data () {
    return {
      menuOpened: false
    }
  }
}
</script>

<style lang="scss" scoped>
  main {
    height: 100%;
  }

  section {
    width: 100%;
    padding: 30px 30px 30px 100px;
    transition: all ease .4s;
    &.tiny {
      padding-left: 400px;
    }
  }
</style>
