<template>
  <nav>
    <div id="sidebar">
      <div id="logo">
        <img src="@/assets/images/logo.png">
      </div>

      <ul>
        <li
          v-for="(item, index) in menu"
          :key="index"
          :class="{ selected: active === item.name }">
          <router-link :to="item.to">
            <i
              :class="item.icon"
              aria-hidden="true" />
            <i
              v-if="active === item.name"
              class="pointer el-icon-caret-left" />
            </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      menu: [
        {
          icon: 'el-icon-document-copy',
          name: 'posts',
          to: { name: 'AdminPosts' }
        },
        {
          icon: 'el-icon-moon',
          name: 'hypno',
          to: { name: 'AdminHypno' }
        },
        {
          icon: 'el-icon-video-play',
          name: 'video',
          to: { name: 'AdminVideo' }
        },
        {
          icon: 'el-icon-back',
          name: 'back',
          to: { name: 'Home' }
        }
        // {
        //   icon: 'el-icon-data-line',
        //   text: 'Statistics'
        // }
      ]
    }
  },

  computed: {
    active () {
      return this.$store.state.page.active
    }
  }
}
</script>

<style lang="scss" scoped>
  nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 70px;
    height: 100%;
    #sidebar {
      height: 100%;
      background-color: $dark;
      position: relative;
      z-index: 99;
      #logo {
        margin-bottom: 40px;
        height: 70px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, .4);
        img {
          height: 40px;
        }
      }
      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          a {
            position: relative;
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding: 15px 0;
            padding-right: 2px;
            margin: 0;
            text-align: center;
            transition: all ease-in-out .2s;
            border-left: 4px solid transparent;
            color: $font-color;
            font-size: 20px;
            &:hover {
              background-color: $dark-secondary;
              border-color: $primary;
              color: #fff;
              cursor: pointer;
            }
            .pointer {
              color: #fff;
              position: absolute;
              right: -12px;
              top: 50%;
              transform: translateY(-50%);
              font-size: 30px;
            }
          }
          
            &.selected a {
              border-color: $primary;
              background-color: $dark-secondary;
            }
        }
      }
    }
    #menu-items {
      position: absolute;
      top: 0;
      left: 70px;
      width: 300px;
      height: 100%;
      margin: 0;
      padding: 0;
      background-color: #fff;
      box-shadow: 0 5px 2px 0 rgba(0, 0, 0, 0.1);
      padding: 30px 0;
      list-style: none;
      z-index: 1;
      font-size: 13px;
      li {
        padding: 10px 25px;
        color: $font-color-dark;
        &.title {
          font-size: 15px;
          color: $dark;
        }
        &.subtitle {
          font-weight: 600;
        }
        a {
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
</style>
