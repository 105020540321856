<template>
  <div id="page">
    <main-header />
    <main>
      <cover
        title="Kontakt"
        :image="image" />


      <div class="wrapper">
        123
      </div>
    </main>
  </div>
</template>

<script>
import Cover from '../common/Cover'
import MainHeader from '../common/Header'
import defaultImage from '@/assets/images/blog.jpg'

export default {
  components: {
    Cover,
    MainHeader
  },

  data () {
    return {
      image: defaultImage
    }
  },

  computed: {
  },
}
</script>

<style lang="scss" scoped>

</style>