<template>
  <div>
    <portal to="nav-buttons">
      <el-input
        v-model="search"
        class="search"
        prefix-icon="el-icon-search"
        placeholder="Pretraga"
        clearable />
      <el-button
        type="info"
        icon="el-icon-plus">
        Dodaj objavu
      </el-button>
    </portal>

    <el-card>
      <el-table
        :data="data"
        style="width: 100%">
        <el-table-column label="Naslov">
          <template slot-scope="scope">
            {{ scope.row.title }}
          </template>
        </el-table-column>
        <el-table-column
          align="right"
          label="Akcije"
          width="160">
          <template slot-scope="scope">
            <el-button-group>
              <el-button size="mini">
                Edit
              </el-button>
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.row)">
                Delete
              </el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      search: null
    }
  },

  computed: {
    data () {
      const posts = this.$store.getters['posts/list']
      return this.search ? posts.filter(post => post.title.toLowerCase().includes(this.search.toLowerCase())) : posts
    }
  },

  created () {
    this.$store.commit('page/set', {
      title: 'Objave',
      active: 'posts',
      breadcrumbs: [
        {
          title: 'Objave'
        }
      ]
    })
  },

  methods: {
    async handleDelete (post) {
      try {
        await this.$confirm('Da li potvrdjujes brisanje ove objave?')
      } catch {
        // Empty
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  width: 250px;
  margin-right: 15px;
}
</style>
