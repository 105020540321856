<template>
  <div>
    123
  </div>
</template>

<script>
export default {
  created () {
    this.$store.commit('page/set', {
      title: 'Video',
      active: 'video',
      breadcrumbs: [
        { 
          title: 'Video'
        }
      ]
    })
  }
}
</script>