<template>
  <div class="video-container">
    <embed
      :src="fullUrl"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen />
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    }
  },

  computed: {
    fullUrl () {
      return `https://www.youtube.com/embed/${this.url}`
    }
  }
}
</script>

<style lang="scss" scoped>
  .video-container {
    overflow: hidden;
    position: relative;
    width:100%;
    margin-bottom: 100px;
  }

  .video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }

  .video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>