import store from '@/store'

export default async () => {
  if (store.state.auth.user) return true
  const token = localStorage.getItem('token')
  if (token) {
    await store.dispatch('auth/me', token)
    return !!store.state.auth.user
  }
  return false
}
