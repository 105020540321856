<template>
  <section
    class="cover"
    :style="{ backgroundImage: `url(${image})` }">
    <div class="main-text">
      <div class="width">
        <h1>{{ title }}</h1>
        <h2 v-if="subtitle">{{ subtitle }}</h2>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },

    subtitle: {
      type: String,
      default: null
    },

    image: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .cover {
    width: 100%;
    height: 600px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: flex-end;
    .main-text {
      width: 100%;
      padding: 70px 0;
      background: transparent;
      background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
    }
  }
</style>