<template>
  <div id="page">
    <main-header />
    <main>
      <home />
    </main>
  </div>
</template>

<script>
import Home from './Home'
import MainHeader from '../common/Header'

export default {
  components: {
    Home,
    MainHeader
  }
}
</script>

<style lang="scss" scoped>
#page {
  width: 100%;
  height: 100%;
  main {
    width: 100%;
    height: 100%;
    .home-main {
      width: 100%;
      height: 100%;
      background-image: url(../../assets/images/bg.jpg);
      background-size: cover;
      background-position: center;
      position: relative;
      display: flex;
      align-items: center;
      .main-text {
        color: #fff;
        padding-top: 100px;
        h1 {
          margin: 0;
          font-weight: 900;
          font-size: 70px;
        }
        h2 {
          font-weight: 400;
          font-size: 40px;
        }
      }
    }
  }
}
</style>