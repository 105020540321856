<template>
  <article>
    <router-link :to="url">
      <div class="cover">
        <img :src="post.image">
      </div>
    </router-link>
    <div class="content">
      <span class="date">{{ post.created_at | date }}</span>
      <router-link :to="url">
        <h3>{{ post.title }}</h3>
      </router-link>
      <div class="text">{{ text }}</div>

      <div class="read-more">
        <router-link :to="url">
          Pogledaj članak <img src="@/assets/images/arrow-black.png">
        </router-link>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    post: {
      type: Object,
      required: true
    }
  },

  computed: {
    url () {
      return {
        name: 'Post',
        params: { id: this.post.id }
      }
    },

    text () {
      const text = this.post.text.replace(/<[^>]*>/g, '');
      return text.substring(0, 200) + "...";
    }
  }
}
</script>

<style lang="scss" scoped>
article {
  width: calc(50% - 30px);
  // border: 1px solid rgba(0, 0, 0, .1);
  box-sizing: border-box;
  .cover {
    img {
      display: block;
      width: 100%;
      max-height: 450px;
      object-fit: cover;
    }
  }
  .content {
    padding: 10px 0;
    h3 {
      margin: 0;
      font-weight: 400;
      font-size: 17px;
      letter-spacing: 1px;
      margin: 5px 0 10px 0;
      color: $secondary;
      &:hover {
        color: $primary-dark;
      }
    }
    .date {
      color: #888;
      font-weight: 100;
      font-size: 13px;
    }
    .text {
      color: #888;
      font-weight: 100;
      font-size: 15px;
      letter-spacing: 1px;
    }
  }
  .read-more {
    margin-top: 15px;
    a {
      img {
        margin-left: 15px;
        vertical-align: middle;
      }
      &:hover {
        color: $primary-dark;
        cursor: pointer;
        img {
          -webkit-animation: mover 1s infinite  alternate;
          animation: mover 1s infinite  alternate;
        }
      }
    }
  }
  // &:hover {
  // }
}
</style>