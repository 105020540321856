<template>
  <div id="page">
    <main-header />
    <main>
      <cover
        title="Zakon Privlačenja Ključ"
        :subtitle="post.title"
        :image="image" />

      <div
        id="post"
        class="width">
        <router-link :to="{ name: 'Blog' }">
          <div class="back">
            <el-button
              icon="el-icon-back"
              circle />
            <span>Nazad na Blog</span>
          </div>
        </router-link>

        <div class="date">{{ post.created_at | date }}.</div>
        <p
          v-html="post.text"
          class="text" />
      </div>
    </main>
  </div>
</template>

<script>
import store from '@/store'
import Cover from '../common/Cover'
import MainHeader from '../common/Header'
import defaultImage from '@/assets/images/blog.jpg'


const beforeRoute = async (to, from, next) => {
  await store.dispatch('posts/get', to.params.id)
  next()
}

export default {
  beforeRouteEnter: beforeRoute,
  beforeRouteUpdate: beforeRoute,

  components: {
    Cover,
    MainHeader
  },

  data () {
    return {
      grid: null
    }
  },

  computed: {
    image () {
      return this.post.image || defaultImage
    },

    postId () {
      return parseInt(this.$route.params.id)
    },

    post () {
      return this.$store.getters['posts/get'](this.postId)
    }
  }
}
</script>

<style lang="scss" scoped>
#page {
  width: 100%;
  height: 100%;
  main {
    width: 100%;
    #post {
      padding: 100px 50px;
      .back {
        margin-bottom: 30px;
        span {
          margin-left: 15px;
        }
        &:hover {
          color: $primary;
          cursor: pointer;
        }
      }
      .date {
        color: #888;
        font-weight: lighter;
        letter-spacing: 1px;
      }
      .text {
        font-size: 17px;
        line-height: 2;
        letter-spacing: .7px;
        font-family: 'Lora', serif;
        &::first-letter {
          font-size: 200%;
          color: $primary;
        }
      }
    }
  }
}
</style>