export default {
  state: {
    title: null,
    subtitle: null,
    active: null,
    breadcrumbs: []
  },

  getters: {},

  actions: {},

  mutations: {
    set (state, data) {
      state.title = data.title || null
      state.subtitle = data.subtitle || null
      state.active = data.active || null
      state.breadcrumbs = data.breadcrumbs || []
    }
  },

  namespaced: true
}
