import { render, staticRenderFns } from "./Post.vue?vue&type=template&id=439c4a50&scoped=true&"
import script from "./Post.vue?vue&type=script&lang=js&"
export * from "./Post.vue?vue&type=script&lang=js&"
import style0 from "./Post.vue?vue&type=style&index=0&id=439c4a50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "439c4a50",
  null
  
)

export default component.exports