<template>
  <div
    id="auth"
    class="flex-center">
    <div class="content-wrapper">
      <div class="side-box flex-center">
        <h1>Zdravo!</h1>
        <span>Unesi email adresu i lozinku kako bi se prijavio/la u svoj admin panel.</span>
      </div>
      <div class="form-wrapper flex-center">
        <h1>Prijava</h1>

        <p v-if="error" class="error">{{ error }}</p>
        <el-form class="form">
          <el-form-item
            label="Email"
            :error="showErrorMessage('username')">
            <el-input v-model="form.username" />
          </el-form-item>
          <el-form-item
            label="Lozinka"
            :error="showErrorMessage('password')">
            <el-input
              v-model="form.password"
              show-password />
          </el-form-item>
          <el-form-item>
            <el-button
              class="button"
              type="info"
              round
              :loading="loading"
              @click="login()">
              Prijavi se
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        username: null,
        password: null
      },
      loading: false,
      error: null,
      requiredField: 'Ovo polje je obavezno.'
    }
  },

  methods: {
    async login () {
      this.$v.form.$touch()
      this.error = null
      if (this.$v.$invalid) return
      this.loading = true
      try {
        await this.$store.dispatch('auth/login', this.form)
        this.$message.success('Uspešno ste se prijavili.')
        this.$router.push({ name: 'AdminPosts' })
      } catch (error) {
        this.error = error.response.status === 400 ? 'Uneti podaci nisu ispravni.' : 'Došlo je do greške.'
      } finally {
        this.loading = false
      }
    },

    showErrorMessage (key) {
      return this.$v.form[key].$invalid && this.$v.form.$dirty ? this.requiredField : null
    }
  },

  validations: {
    form: {
      username: { required },
      password: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
  #auth {
    width: 100%;
    height: 100%;
    background-image: url(../../assets/images/auth-bg.jpg);
    background-size: cover;
    .content-wrapper {
      max-width: 900px;
      border-radius: 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      background-image: url(../../assets/images/auth-box-bg.png);
      background-size: cover;
      overflow: hidden;
      .form-wrapper {
        width: 60%;
        text-align: center;
        flex-direction: column;
        padding: 120px 80px;
        background-color: #fff;
        h1 {
          font-size: 30px;
          margin: 0 0 50px 0;
        }
        .form {
          width: 350px;
        }
      }

      .side-box {
        height: 100%;
        padding: 50px;
        flex-direction: column;
        text-align: center;
        color: #fff;
        h1 {
          font-size: 30px;
          margin: 0 0 30px 0;
        }
        span {
          display: block;
          line-height: 1.5;
          margin-bottom: 30px;
        }
      }
    }
  }
  .button {
    padding: 15px 70px;
    border-radius: 23px;
  }

  .error {
    color: #d54c4c;
    margin: 0 0 20px 0;
  }
</style>
