import Vue from 'vue'
import Vuex from 'vuex'

import page from './page'
import auth from './auth'
import posts from './posts'
import videos from './videos'
import hypno from './hypno'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    page,
    auth,
    posts,
    videos,
    hypno
  }
})
