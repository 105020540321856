import Blog from '../components/blog/Blog'
import Post from '../components/blog/Post'
import Video from '../components/video/Video'
import Contact from '../components/contact/Contact'
import LandingPage from '../components/home/LandingPage'
import Hypnotherapy from '../components/blog/Hypnotherapy'
import Login from '../components/admin/Login'
import AdminView from '../components/admin/AdminView'
import AdminPosts from '../components/admin/Posts'
import AdminHypno from '../components/admin/Hypno'
import AdminVideo from '../components/admin/Video'

export default [
  {
    path: '/',
    name: 'Home',
    component: LandingPage
  },
  {
    path: '/blog',
    name: 'Blog',
    component: Blog
  },
  {
    path: '/hipnoterapija',
    name: 'Hypnotherapy',
    component: Hypnotherapy
  },
  {
    path: '/objava/:id',
    name: 'Post',
    component: Post
  },
  {
    path: '/video',
    name: 'Video',
    component: Video
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    component: AdminView,
    redirect: { name: 'AdminPosts' },
    meta: { auth: true },
    children: [
      {
        path: 'posts',
        component: AdminPosts,
        name: 'AdminPosts'
      },
      {
        path: 'hypno',
        component: AdminHypno,
        name: 'AdminHypno'
      },
      {
        path: 'video',
        component: AdminVideo,
        name: 'AdminVideo'
      }
    ]
  }
]