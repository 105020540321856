<template>
  <router-view v-if="loaded" />
</template>

<script>
export default {
  data () {
    return {
      loaded: false
    }
  },

  async created () {
    const promises = [
      this.$store.dispatch('posts/load'),
      this.$store.dispatch('hypno/load')
    ]
    await Promise.all(promises)
    this.loaded = true
  }
}
</script>
