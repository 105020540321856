import Vue from 'vue'
import moment from 'moment'

moment.locale('rs')

moment.updateLocale('rs', {
  months: ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun', 'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar']
});

Vue.filter('date', (date, format = 'LL') => {
  return moment(date).format(format)
})