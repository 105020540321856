<template>
  <div id="page">
    <main-header />
    <main>
      <cover
        title="Blog"
        :image="image" />

      <div class="wrapper">
        <div
          id="posts"
          class="width">
          <post-preview
            v-for="post in posts"
            :key="post.id"
            :post="post" />

          <div v-if="loading && !noMore">Učitavanje</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import Cover from '../common/Cover'
import PostPreview from './PostPreview'
import MainHeader from '../common/Header'
import defaultImage from '@/assets/images/blog.jpg'

import MagicGrid from 'magic-grid'

export default {
  components: {
    Cover,
    MainHeader,
    PostPreview
  },

  data () {
    return {
      grid: null,
      loading: false,
      postsWrapper: false,
      image: defaultImage
    }
  },

  computed: {
    noMore () {
      const meta = this.$store.state.posts.meta
      return meta.current_page === meta.total_pages
    },

    disabled () {
      return this.loading || this.noMore
    },

    posts () {
      return this.$store.getters['posts/list']
    }
  },

  watch: {
    'posts.length' () {
      this.initGrid()
    }
  },

  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.postsWrapper = document.getElementById('posts')
    setTimeout(this.initGrid, 300)
  },

  methods: {
    handleScroll () {
      if (this.postsWrapper.getBoundingClientRect().bottom < window.innerHeight && !this.disabled) {
        this.loadMore()
      }
    },

    async loadMore () {
      this.loading = true
      await this.$store.dispatch('posts/load', true)
      this.loading = false
    },

    initGrid () {
      this.grid = new MagicGrid({
        container: '#posts',
        items: this.posts.length,
        animate: true,
        gutter: 30,
        useMin: true
      })

      this.grid.listen()
    }
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
