<template>
  <nav class="flex-v-center">
    <div class="page-info">
      <div class="title">
        <h1>{{ title }}</h1>
        <h2 v-if="subtitle">{{ subtitle }}</h2>
      </div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item
          v-for="breadcrumb in breadcrumbs"
          :key="breadcrumb.title"
          :to="breadcrumb.to">
          {{ breadcrumb.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="pull-right">
      <portal-target name="nav-buttons" />
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    title () {
      return this.$store.state.page.title
    },

    subtitle () {
      return this.$store.state.page.subtitle
    },

    breadcrumbs () {
      return this.$store.state.page.breadcrumbs
    }
  }
}
</script>

<style lang="scss" scoped>
  nav {
    width: 100%;
    margin-bottom: 50px;
    .page-info {
      flex-grow: 1;
      .title {
        margin-bottom: 15px;
        h1 {
          margin: 0;
          display: inline-block;
          font-size: 20px;
          font-weight: bold;
          margin-right: 15px;
          text-transform: uppercase;
        }
        h2 {
          margin: 0;
          display: inline-block;
          font-size: 14px;
          font-weight: normal;
          text-transform: uppercase;
        }
      }
    }
  }
</style>
