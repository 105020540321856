<template>
  <div>
    <div class="overlay" />
    <header>
      <nav class="width">
        <div class="main">
          <div id="logo">
            <router-link :to="{ name: 'Home' }">
              <img src="@/assets/images/logo.png">
            </router-link>
          </div>
          <div class="hamburger">
            <button
              class="btn-hamburger"
              :class="{ open }"
              @click="open = !open">
              <span class="line"></span>
            </button>
          </div>
        </div>
        <transition name="slide-fade">
          <div
            v-show="open"
            class="menu">
            <ul>
              <li
                v-for="item in menu"
                :key="item.name">
                <router-link :to="item.to">{{ item.name }}</router-link>
              </li>
            </ul>
          </div>
        </transition>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  data () {
    return {
      open: false,

      menu: [
        {
          name: 'Početna',
          to: { name: 'Home' }
        },
        {
          name: 'Blog',
          to: { name: 'Blog' }
        },
        {
          name: 'Video',
          to: { name: 'Video' }
        },
        {
          name: 'Hipnoterapija',
          to: { name: 'Hypnotherapy' }
        }
        // {
        //   name: 'Kontakt',
        //   to: { name: 'Contact' }
        // }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  $height: 300px;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 98;
    width: 100%;
    height: $height;
    background: transparent;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
  }
  header {
    position: absolute;
    top: 0;
    z-index: 99;
    width: 100%;
    nav {
      padding-top: 120px;
      display: flex;
      align-items: center;
      .main {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: relative;
        z-index: 10;
        #logo {
          img {
            height: 50px;
          }
        }
      }
      .menu {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: rgba(0,0,0,.9);
        background: linear-gradient(90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,.9) 30%, rgba(0,0,0,.9) 70%, rgba(0,0,0,0.8) 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s $easing;
        ul {
          list-style: none;
          li {
            margin: 30px 0;
            text-align: center;
            a {
              color: #fff;
              font-size: 30px;
              font-weight: bold;
              transition: all 0.3s $easing;
              &:hover {
                color: $primary;
              }
              &.router-link-exact-active {
                color: $secondary;
              }
            }
          }
        }
      }
    }
  }
</style>