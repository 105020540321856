import Vue from 'vue'
import axios from 'axios'

export default {
  state: {
    ids: [],
    data: {}
  },

  getters: {
    list: (state) => state.ids.map(id => state.data[id]),
    get: (state) => (id) => state.data[id]
  },

  actions: {
    async get ({ state, commit }, id) {
      if (state.data[id]) return state.data[id]

      const response = await axios.get(`/videos/${id}`)
      commit('set', response.data.data)
      return response.data.data
    },

    async load ({ commit }) {
      const response = await axios.get('/videos')
      commit('setMultiple', response.data)
      return response.data
    }
  },

  mutations: {
    setMultiple (state, data) {
      data.data.forEach(item => {
        state.ids.push(item.id)
        Vue.set(state.data, item.id, item)
      })
    },

    set (state, item) {
      Vue.set(state.data, item.id, item)
    }
  },

  namespaced: true
}
