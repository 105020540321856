import axios from 'axios'
import config from '../../config'

export default {
  state: {
    user: null
  },

  getters: {},

  actions: {
    async login ({ dispatch }, data) {
      const payload = {
        grant_type: 'password',
        client_id: '2',
        client_secret: 'HNuzaLBBt4t1KcyfVbtJFmCFUANtIzxzrzy5uT0e',
        scope: '*',
        ...data
      }
      try {
        const response = await axios({
          method: 'POST',
          url: '/oauth/token',
          baseURL: config.data.baseURL,
          data: payload
        })
        const token = response.data.access_token
        await dispatch('me', token)
        return Promise.resolve(token)
      } catch (error) {
        return Promise.reject(error)
      }
    },

    logout ({ commit }) {
      localStorage.removeItem('token')
      axios.defaults.headers.common.Authorization = null
      commit('set', null)
    },

    async me ({ commit }, token) {
      try {
        localStorage.setItem('token', token)
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        const response = await axios.get('/user')
        commit('set', response.data.data)
        return response.data.data
      } catch (error) {
        axios.defaults.headers.common.Authorization = null
        localStorage.removeItem('token')
        return false
      }
    }
  },

  mutations: {
    set (state, data) {
      state.user = data
    }
  },

  namespaced: true
}
