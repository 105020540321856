import Vue from 'vue'
import axios from 'axios'

export default {
  state: {
    ids: [],
    data: {},
    meta: null,
    page: 1
  },

  getters: {
    list: (state) => state.ids.map(id => state.data[id]),
    get: (state) => (id) => state.data[id]
  },

  actions: {
    async load ({ state, commit }, nextPage = false) {
      const payload = {
        params: {
          type: 'HIPNOTERAPIJA'
        }
      }
      if (nextPage) {
        state.page++
        payload.params.page = state.page
      }
      const response = await axios.get('/posts', payload)
      commit('setMultiple', response.data)
      return response.data
    }
  },

  mutations: {
    setMultiple (state, data) {
      data.data.forEach(post => {
        state.ids.push(post.id)
        Vue.set(state.data, post.id, post)
      })
      Vue.set(state, 'meta', data.meta.pagination)
    },

    set (state, post) {
      Vue.set(state.data, post.id, post)
    }
  },

  namespaced: true
}
